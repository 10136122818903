img {
    &.u-center {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    &.u-left {
        display: block;
        float: left;
        margin-right: @space;
    }

    &.u-right {
        display: block;
        float: right;
        margin-left: @space;
    }
}

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }
.embed-container iframe,
.embed-container object,
.embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
