
#box {
    .rounded {

        .small {
            border-radius: @border-radius-default;
        }

        .medium {
            border-radius: @border-radius-default;
        }

        .large {
            border-radius: @border-radius-default;
        }
    }

    .shadow {
        -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.50);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.50);
    }

}
