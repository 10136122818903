
// The content utility is mainly used for areas where a user can add content.
// It provides nice styles for basic content elements like text and images.
// This allows us to have user-friendly styles for certain areas without having them apply to the page as a whole.

.u-content {

    a:active {
        text-decoration: none;
    }

    p {
        margin-bottom: 1em;

        small {
            margin-bottom: 1em;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0.7em;
    }

    p, h1, h2, h3, h4, h5, h6 {
        &:last-child {
            margin-bottom: 0;
        }
    }

}

// We declare modifiers here rather than nested under .u-content so they can be used as mixins.
// (LESS is'nt clever enough to auto-concat the nested name structure when addressing the mixin).

.u-content {

    &--bold {
        font-weight: 700;
    }

    &--light {
        .typographyColor(white);
    }

    &--dark {
        .typographyColor(@colour-dark);
    }

    &--lg {
        font-size: 1.3em;
    }

    &--sm {
        font-size: 0.9em;
    }

    &--xs {
        font-size: 0.8em;
    }

    &--left {
        text-align: left;
    }

    &--centre {
        text-align: center;
    }

    &--right {
        text-align: right;
    }
}
