/*  ====== Theme Variables ======
    Unique to this project
    ========================================================================= */

//@import (reference) 'resources/styles/vendor/bootstrap/bootstrapBase.less'; // Comment this out if you're not using variables or mixins
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

// Colours
@colour-primary: #01559e;
@colour-primary-darker: darken(@colour-primary, 10%);
@colour-secondary: #f03f35;
@colour-secondary-darker: darken(@colour-secondary, 10%);

@colour-neutral: #f5f5f5;
@colour-neutral-darker: darken(@colour-neutral, 5%);

@colour-dark: #383838;
@colour-darker: darken(#383838, 10%);

@colour-background: #FFF;

// Graphic defaults
@border-radius-default: 4px;
@border-default: 1px @colour-dark solid;

// Typography
@font-family: 'Open Sans', 'Ariel', sans-serif;
@font-family-heading: 'Open Sans', 'Ariel', sans-serif;

@font-size: 1em;
@line-height: 1.4;
@font-colour: @colour-dark;
@font-colour-heading: #231f20;
@font-colour-link: @colour-primary;

// Set a ratio for headings and other bits of typography
// Golden Ratio = 1.6
// A More sensible ratio is 1.2
@font-size-ratio: 1.2;

@font-size-h6: @font-size;
@font-size-h5: @font-size;
@font-size-h4: @font-size * @font-size-ratio;
@font-size-h3: @font-size-h4 * @font-size-ratio;
@font-size-h2: @font-size-h3 * @font-size-ratio;
@font-size-h1: @font-size-h2 * @font-size-ratio;

// Spacing
// Use these variables to keep spacing consistent throughout the site

// The base size of spaces & padding
@space: 3rem;

// For column/grid layouts
@gutter: 30px;

// A multiplier used for lg, sm and so on
@space-ratio: 2;

// Media queries
// Normal example: @media @mobile {...}
// Multi breakpoint example: @media @mobile, @tablet {..}

@breakpoint: 768px;
@breakpoint-min: @breakpoint + 1;

@screen-xs: 480px;
@screen-xs-max: (@screen-sm - 1);

@screen-sm: 768px;
@screen-sm-max: (@screen-md - 1);

@screen-md: 1024px;
@screen-md-max: (@screen-lg - 1);

@screen-lg: 1366px;
@screen-lg-max: (@screen-xl - 1);

@screen-xl: 1680px;

@mobile: ~"only screen and (max-width: @{screen-xs-max})";
@tablet: ~"only screen and (min-width: @{screen-sm}) and (max-width: @{screen-sm-max})";
@desktop: ~"only screen and (min-width: @{screen-md}) and (max-width: @{screen-md-max})";
@desktop-xl: ~"only screen and (min-width: @{screen-lg})";
