/*  ====== Theme Variables ======
    Unique to this project
    ========================================================================= */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
html {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  background-color: #013a6b;
  font-family: 'Open Sans', 'Ariel', sans-serif;
  color: #383838;
  font-size: 1em;
  line-height: 1.4;
}
body.public {
  animation: bodyfadein 0.3s;
}
@keyframes bodyfadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
img {
  border: none;
  max-width: 100%;
  height: auto;
}
* {
  box-sizing: border-box;
}
.MainLayout {
  display: flex;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
.MainLayout-side {
  flex: 0 0 260px;
  order: 0;
  background-color: #FFF;
  box-shadow: rgba(0, 0, 0, 0.4) 0 -2px 14px;
  z-index: 1;
}
.MainLayout-main {
  flex: 1;
  order: 1;
  position: relative;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1680px) {
  .MainLayout-side {
    flex-basis: 360px;
  }
}
@media screen and (max-width: 1023px) {
  .MainLayout {
    display: block;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .MainLayout-main {
    display: block;
  }
}
.lt-ie10 .MainLayout-side {
  float: left;
  width: 20%;
}
.lt-ie10 .MainLayout-main {
  float: right;
  width: 80%;
}
a {
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out, border 0.2s ease-in-out;
  font-weight: normal;
  color: #01559e;
  text-decoration: none;
}
a:hover {
  color: #013a6b;
  text-decoration: none;
}
p {
  margin: 0;
  padding: 0;
  line-height: 1.4;
}
p strong {
  font-weight: bold;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px 0;
  padding: 0;
  font-family: 'Open Sans', 'Ariel', sans-serif;
  color: #231f20;
  line-height: 1.4;
  font-weight: 400;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  display: inline-block;
}
h1 small:before,
h2 small:before,
h3 small:before,
h4 small:before,
h5 small:before,
h6 small:before {
  content: ' ';
}
h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a,
a > h1,
a > h2,
a > h3,
a > h4,
a > h5,
a > h6 {
  color: #01559e;
  text-decoration: none;
  font-weight: inherit;
}
h1 {
  font-size: 2.0736em;
}
h2 {
  font-size: 1.728em;
}
h3 {
  font-size: 1.44em;
}
h4 {
  font-size: 1.2em;
}
h5 {
  font-size: 1em;
}
h6 {
  font-size: 1em;
}
blockquote {
  margin: 0;
  padding-left: 2em;
  font-family: Georgia, Times, cursive;
  font-style: italic;
  font-size: 2em;
  line-height: 1.4;
  color: #525252;
  border-left: 1px #383838 solid;
  border-left-width: 4px;
}
ul,
ol {
  margin: 0;
  padding: 0;
}
ul li,
ol li {
  margin-bottom: 1.4em;
  margin-left: 1.4em;
}
hr {
  border: none;
  border-top: 1px #383838 solid;
}
.fa-text-right {
  margin-right: .5em;
}
#box .rounded .small {
  border-radius: 4px;
}
#box .rounded .medium {
  border-radius: 4px;
}
#box .rounded .large {
  border-radius: 4px;
}
#box .shadow {
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}
.no-gap {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.u-content a:active {
  text-decoration: none;
}
.u-content p {
  margin-bottom: 1em;
}
.u-content p small {
  margin-bottom: 1em;
}
.u-content h1,
.u-content h2,
.u-content h3,
.u-content h4,
.u-content h5,
.u-content h6 {
  margin-bottom: 0.7em;
}
.u-content p:last-child,
.u-content h1:last-child,
.u-content h2:last-child,
.u-content h3:last-child,
.u-content h4:last-child,
.u-content h5:last-child,
.u-content h6:last-child {
  margin-bottom: 0;
}
.u-content--bold {
  font-weight: 700;
}
.u-content--light {
  color: white;
}
.u-content--light p {
  color: white;
}
.u-content--light h1,
.u-content--light h2,
.u-content--light h3,
.u-content--light h4,
.u-content--light h5,
.u-content--light h6 {
  color: white;
}
.u-content--light h1 small,
.u-content--light h2 small,
.u-content--light h3 small,
.u-content--light h4 small,
.u-content--light h5 small,
.u-content--light h6 small {
  color: #ffffff;
}
.u-content--light a {
  color: white;
}
.u-content--light a:hover {
  color: #e6e6e6;
}
.u-content--dark {
  color: #383838;
}
.u-content--dark p {
  color: #383838;
}
.u-content--dark h1,
.u-content--dark h2,
.u-content--dark h3,
.u-content--dark h4,
.u-content--dark h5,
.u-content--dark h6 {
  color: #383838;
}
.u-content--dark h1 small,
.u-content--dark h2 small,
.u-content--dark h3 small,
.u-content--dark h4 small,
.u-content--dark h5 small,
.u-content--dark h6 small {
  color: #ffffff;
}
.u-content--dark a {
  color: #383838;
}
.u-content--dark a:hover {
  color: #1e1e1e;
}
.u-content--lg {
  font-size: 1.3em;
}
.u-content--sm {
  font-size: 0.9em;
}
.u-content--xs {
  font-size: 0.8em;
}
.u-content--left {
  text-align: left;
}
.u-content--centre {
  text-align: center;
}
.u-content--right {
  text-align: right;
}
img.u-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
img.u-left {
  display: block;
  float: left;
  margin-right: 3rem;
}
img.u-right {
  display: block;
  float: right;
  margin-left: 3rem;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.u-clear {
  clear: both;
}
.u-clearFix:before,
.u-clearFix:after {
  content: " ";
  display: table;
}
.u-clearFix:after {
  clear: both;
}
.u-block {
  display: block;
}
.u-inlineBlock {
  display: inline-block;
}
.u-inline {
  display: inline;
}
hr.u-spacer {
  border: none;
  margin: 0;
}
.row--maxHeight {
  height: 100%;
}
p.u-lead {
  font-size: 1.4em;
  font-weight: 300;
}
h1.u-lead,
h2.u-lead,
h3.u-lead,
h4.u-lead,
h5.u-lead,
h6.u-lead,
.u-leadHeading {
  border-bottom: 1px solid #01559e;
}
.u-textLeft {
  text-align: left;
}
.u-textRight {
  text-align: right;
}
.u-textCenter {
  text-align: center;
}
.u-textJustify {
  text-align: justify;
}
.u-textNoWrap {
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .u-textCenter--sm {
    text-align: center;
  }
  .u-textleft--sm {
    text-align: left;
  }
  .u-textRight--sm {
    text-align: right;
  }
}
@media screen and (max-width: 768px) {
  .u-textCenter--md {
    text-align: center;
  }
  .u-textleft--md {
    text-align: left;
  }
  .u-textRight--md {
    text-align: right;
  }
}
/**
 * Enables font kerning in all browsers.
 * http://blog.typekit.com/2014/02/05/kerning-on-the-web/
 *
 * 1. Chrome (not Windows), Firefox, IE 10+
 * 2. Safari 7 and future browsers
 * 3. Chrome (not Windows), Firefox, Safari 6+, iOS, Android
 */
.u-textKern {
  font-feature-settings: "kern" 1;
  /* 1 */
  font-kerning: normal;
  /* 2 */
  text-rendering: optimizeLegibility;
  /* 3 */
}
/**
 * Text truncation
 *
 * Prevent text from wrapping onto multiple lines, and truncate with an
 * ellipsis.
 *
 * 1. Ensure that the node has a maximum width after which truncation can
 *    occur.
 * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
 *    nodes.
 */
.u-textTruncate {
  max-width: 100%;
  /* 1 */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
  /* 2 */
}
.u-textLowerCase {
  text-transform: lowercase;
}
.u-textUpperCase {
  text-transform: uppercase;
}
.u-textCapitalize {
  text-transform: capitalize;
}
.u-noJsShow {
  display: none !important;
}
.no-js .u-noJsShow {
  display: block !important;
}
.no-js .u-noJsHide {
  display: none !important;
}
.mce-content-body {
  background: #fff;
  padding: 15px;
}
.mce-content-body a:active {
  text-decoration: none;
}
.mce-content-body p {
  margin-bottom: 1em;
}
.mce-content-body p small {
  margin-bottom: 1em;
}
.mce-content-body h1,
.mce-content-body h2,
.mce-content-body h3,
.mce-content-body h4,
.mce-content-body h5,
.mce-content-body h6 {
  margin-bottom: 0.7em;
}
.mce-content-body p:last-child,
.mce-content-body h1:last-child,
.mce-content-body h2:last-child,
.mce-content-body h3:last-child,
.mce-content-body h4:last-child,
.mce-content-body h5:last-child,
.mce-content-body h6:last-child {
  margin-bottom: 0;
}
.mce-content-body--bold {
  font-weight: 700;
}
.mce-content-body--light {
  color: white;
}
.mce-content-body--light p {
  color: white;
}
.mce-content-body--light h1,
.mce-content-body--light h2,
.mce-content-body--light h3,
.mce-content-body--light h4,
.mce-content-body--light h5,
.mce-content-body--light h6 {
  color: white;
}
.mce-content-body--light h1 small,
.mce-content-body--light h2 small,
.mce-content-body--light h3 small,
.mce-content-body--light h4 small,
.mce-content-body--light h5 small,
.mce-content-body--light h6 small {
  color: #ffffff;
}
.mce-content-body--light a {
  color: white;
}
.mce-content-body--light a:hover {
  color: #e6e6e6;
}
.mce-content-body--dark {
  color: #383838;
}
.mce-content-body--dark p {
  color: #383838;
}
.mce-content-body--dark h1,
.mce-content-body--dark h2,
.mce-content-body--dark h3,
.mce-content-body--dark h4,
.mce-content-body--dark h5,
.mce-content-body--dark h6 {
  color: #383838;
}
.mce-content-body--dark h1 small,
.mce-content-body--dark h2 small,
.mce-content-body--dark h3 small,
.mce-content-body--dark h4 small,
.mce-content-body--dark h5 small,
.mce-content-body--dark h6 small {
  color: #ffffff;
}
.mce-content-body--dark a {
  color: #383838;
}
.mce-content-body--dark a:hover {
  color: #1e1e1e;
}
.mce-content-body--lg {
  font-size: 1.3em;
}
.mce-content-body--sm {
  font-size: 0.9em;
}
.mce-content-body--xs {
  font-size: 0.8em;
}
.mce-content-body--left {
  text-align: left;
}
.mce-content-body--centre {
  text-align: center;
}
.mce-content-body--right {
  text-align: right;
}
